import { lazy, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getAllParentModules } from '../../apis/backend/modules';
import AuthContext from '../../contexts/authContext';
import Login from '../../pages/presentation/auth/Login';
import verifyAccess from '../../utils/verifyAccess';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));

const ContentRoutes = () => {
	const { userData } = useContext(AuthContext);
	const [isLoadingFinsh, setLoadingFinsh] = useState(false);
	const [modulesMenu, setModulesMenu] = useState<any[]>([
		{
			id: '404',
			path: '*',
			element: <PAGE_404 />,
			exact: true,
		},
		{
			id: 'login',
			path: 'auth-pages/login',
			element: <Login />,
			exact: true,
		},
	]);

	useEffect(() => {
		if (userData?.accessToken && userData?.role == 'employe') {
			getAllParentModules(userData?.accessToken, async (modules: any) => {
				const newModulesMenu: any[] = [];

				if (modules?.length) {
					await Promise.all(
						modules.map((module: any) => {
							if (module?.subModules?.length) {
								module?.subModules.map(async (subModule: any) => {
									const LoadedPageModule = lazy(
										() =>
											import(
												`../../pages/${subModule.route.pageComponentPath}`
											),
									);
									newModulesMenu.push({
										id: subModule.id,
										path: subModule.route.path,
										element: (
											<LoadedPageModule
												hasAccess={(access: String) => {
													return verifyAccess(subModule?.access, access);
												}}
											/>
										),
										exact: true,
									});

									!!subModule?.othersPagesPath?.length &&
										subModule?.othersPagesPath.map((route: any) => {
											const LoadedPage = lazy(
												() =>
													import(
														`../../pages/${route.pageComponentPath}`
													),
											);
											newModulesMenu.push({
												id: route.id,
												path: route.path,
												element: (
													<LoadedPage
														hasAccess={(access: String) => {
															return verifyAccess(
																subModule?.access,
																access,
															);
														}}
													/>
												),
												exact: true,
											});
										});
								});
							}
						}),
					);
				}

				setModulesMenu([...modulesMenu, ...newModulesMenu]);
				setLoadingFinsh(true);
			});
		} else if (userData?.accessToken && userData?.role == 'sup_admin') {
			const PagesEnterprises = lazy(() => import('../../pages/presentation/Enterprises'));
			const SingleEnterprise = lazy(
				() => import('../../pages/presentation/Enterprises/SingleEnterprise'),
			);
			const PagesModules = lazy(() => import('../../pages/presentation/Modules'));
			setModulesMenu([
				...modulesMenu,
				{
					id: 'gestionEnterprise',
					path: 'enterprises',
					element: <PagesEnterprises />,
					exact: true,
				},
				{
					id: 'gestionEnterprise',
					path: 'enterprises/:id',
					element: <SingleEnterprise />,
					exact: true,
				},
				{
					id: 'gestionModules',
					path: 'modules',
					element: <PagesModules />,
					exact: true,
				},
				{
					id: 'gestionModules',
					path: 'modules/:id',
					element: <PagesModules />,
					exact: true,
				},
			]);
			setLoadingFinsh(true);
		} else if (userData?.accessToken && userData?.role == 'company') {
			const PagesComptes = lazy(() => import('../../pages/presentation/CompanyComptes'));
			const COMPTES_SINGLE = lazy(
				() => import('../../pages/presentation/CompanyComptes/SingleCompte'),
			);
			setModulesMenu([
				...modulesMenu,
				{
					id: 'gestionComptes',
					path: 'comptes',
					element: <PagesComptes />,
					exact: true,
				},
				{
					id: 'gestionComptesSingle',
					path: `comptes/:id`,
					element: <COMPTES_SINGLE />,
					exact: true,
				},
			]);
			setLoadingFinsh(true);
		} else {
			setLoadingFinsh(true);
		}
	}, []);

	return (
		<Routes>
			{isLoadingFinsh &&
				modulesMenu?.length &&
				modulesMenu?.map((route) => {
					return <Route key={route?.id} {...route} />;
				})}

			{/* { PageTest && <Route  {...PageTest} />} */}

			{/* {contents.map((page:any) => (
				// eslint-disable-next-line react/jsx-props-no-spreading
				<Route key={page.path} {...page} />
			))} */}

			{/* <Route path='*' element={<PAGE_404 />} /> */}
		</Routes>
	);
};

export default ContentRoutes;
