import { Suspense } from 'react';
import Page from '../Page/Page';
import PageWrapper from '../PageWrapper/PageWrapper';
import ContentRoutes from './ContentRoutes';

const LOADING = (
	<PageWrapper isProtected={false} title='content'>
		<Page
			container='fluid'
			className='shadow justify-content-center text-center  fs-1 fw-semibold rounded-5 my-3'>
			Loading caisse ...
		</Page>
		{/* <SubHeader>
			<div />
		</SubHeader>
		<Page>
			<div className='row h-100'>
				<div className='col-lg-6'>
					<Card stretch>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
				<div className='col-lg-6'>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
					<Card stretch='semi'>
						<CardBody>
							<div />
						</CardBody>
					</Card>
				</div>
			</div>
		</Page> */}
	</PageWrapper>
);

const Content = () => {
	return (
		<main className='content'>
			<Suspense fallback={LOADING}>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
