import { useContext, useEffect, useState } from 'react';
import { formattedAmount } from '../../common/function/functionsUtils';
import AuthContext from '../../contexts/authContext';
import { searchAPI } from '../../nodeApi/cruds';
import Icon from '../icon/Icon';

export default function HistoriqueCmdPopup({ clientId }) {
    const { userData } = useContext(AuthContext);


    const STATUS = [
        { label: 'Tous', value: 'all', color: 'info' },
        { label: 'Nouvelle', value: 'pending', color: 'warning' },
        { label: 'Annuler', value: 'cancelled', color: 'danger' },
        { label: 'Colis', value: 'colis', color: 'info' },
        { label: 'Colis', value: 'En attente', color: 'info' },
        { label: 'Retournée', value: 'returned', color: 'danger' },
        { label: 'Echange reçu', value: 'exchange_received', color: 'success' },
        { label: 'Echange non reçu', value: 'exchange_not_received', color: 'danger' },
        { label: 'Imprimé', value: 'A Enlever', color: 'danger' },
        { label: 'Enlevé', value: 'A livrer', color: 'success' },
        { label: 'Valider', value: 'validated', color: 'success' },

    ];

    const [commandes, setCommandes] = useState([])
    useEffect(() => {

        const getCmds = async () => {
            const filter = { client: { $in: [clientId] } }

            const result = await searchAPI(
                userData?.accessToken,
                'order',
                filter,
                0,
                0
            );
            setCommandes(result?.elements)
        }
        !!clientId ? getCmds() : setCommandes([])
        console.log("clientId", clientId)
    }, [clientId])

    return (

        <div className='row g-4 px-4 pb-0'>
            <span className='fw-bold fs-4'>Historique des commandes</span>
            <div className='table-responsive'>
                <table className='table table-modern'>
                    <thead>
                        <tr>
                            <th scope='col' className='text-start'>Réf. Commande</th>
                            <th scope='col'>Date</th>
                            {/* <th scope='col'>Tentatives</th>
                            <th scope='col'>Statut</th> */}
                            <th scope='col'>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {commandes?.map((i, index) => (
                            <tr key={index}>
                                <td className='text-start'>
                                    <div>
                                        {i?.receiptNumber}
                                        <h6 className='text-muted ms-4'>{i?.id_colis}</h6>
                                    </div>
                                </td>
                                <td>
                                    <div className='d-flex gap-1'>
                                        <div>
                                            {new Date(i?.createdAt).toLocaleDateString()}
                                            <h6 className='text-muted'>
                                                {new Date(
                                                    i?.createdAt,
                                                ).toLocaleTimeString()}
                                            </h6>
                                        </div>
                                    </div>
                                </td>


                                {/* <td>
                                    <Icon icon='PhoneInTalk' size='2x' color='info' />{' '}
                                    {i?.nb_relance || 0}
                                </td> */}
                                {/* <td>
                                    <button
                                        className={`btn btn-outline-${STATUS?.find(
                                            (status) => i?.status === status?.value,
                                        )?.color
                                            } border-transparent text-nowrap ps-0`}>
                                        <Icon
                                            icon={
                                                i?.status === 'sorti' ||
                                                    i?.status === 'sorti_accepted'
                                                    ? 'ArrowBack'
                                                    : 'Circle'
                                            }
                                            size='lg'
                                            color={
                                                STATUS?.find(
                                                    (status) => i?.status === status?.value,
                                                )?.color
                                            }
                                        />{' '}
                                        {
                                            STATUS?.find((status) => i?.status === status?.value)?.label || i?.status
                                        }
                                    </button>
                                </td> */}

                                <td>
                                    <span className='text-info fw-semibold'>
                                        {formattedAmount(i?.totalPrice)}
                                    </span>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>


            </div>


        </div>


    );
}
