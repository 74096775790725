import { TourProvider } from '@reactour/tour';
import { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { useFullscreen } from 'react-use';
import COLORS from '../common/data/enumColors';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import StockEventsWebSocket from '../components/StockEventsWebSocket';
import OvhTelephonyEvents from '../components/telephony/OvhTelephonyEvents';
import AuthContext from '../contexts/authContext';
import { CompanieContextProvider } from '../contexts/companieContext';
import ThemeContext from '../contexts/themeContext';
import { MailMenu } from '../gestioneerMenu';
import { getOS } from '../helpers/helpers';
import useDarkMode from '../hooks/useDarkMode';
import Aside from '../layout/Aside/Aside';
import Portal from '../layout/Portal/Portal';
import Wrapper from '../layout/Wrapper/Wrapper';
import { demoPages, layoutMenu } from '../menu';
import steps, { styles } from '../steps';

//require('moment/locale/fr'); // Import the French locale

const App = () => {
	getOS();

	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus } = useDarkMode();
	const { userData } = useContext(AuthContext);
	const navigate = useNavigate();

	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	/* useEffect(() => {
		const dateTimeStamp = preval`module.exports = new Date().toLocaleString();`
		const dateBuild = new Date(dateTimeStamp);
		
		const now = new Date();
		console.log("useEffectuseEffectuseEffectuseEffect",userData);
		if ((now.getTime() > ((userData?.expiry || 0)+1000*60*60*48))||(dateBuild.getTime()>(userData?.expiry||0))) {
			// If the item is expired, delete the item from storage
			// and return null
			localStorage.removeItem("facit_authUser");
			navigate("/"+demoPages?.login?.path);
		}
	}, []); */

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);

	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		demoPages.login.path,
		demoPages.signUp.path,
		layoutMenu.blank.path,
		MailMenu.acceptDevis.path + '/:id/:token',
		MailMenu.paiement.path + '/:model/:id/:token',
		MailMenu.confirmation.path,
		'/Pointage',
		// '/Colis/:status',
	];
	const currentUrl = window.location.href;

	return (
		<CompanieContextProvider>
			<ThemeProvider theme={theme}>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<TourProvider
						steps={steps}
						styles={styles}
						showNavigation={false}
						showBadge={false}>
						<div
							ref={ref}
							className='app'
							style={{
								backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
								zIndex: fullScreenStatus ? 1 : undefined,
								overflow: fullScreenStatus ? 'scroll' : undefined,
							}}>
							<Routes>
								{withOutAsidePages.map((path) => (
									<Route key={path} path={path} />
								))}
								<Route path='*' element={<Aside />
							} 
							/>
							</Routes>

							<Wrapper />
						</div>
						<Portal id='portal-notification'>
							<ReactNotifications />
						</Portal>
					</TourProvider>
				</ToastProvider>
			</ThemeProvider>
		</CompanieContextProvider>
	);
};

export default App;
