import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ThemeContext from '../../contexts/themeContext';

import { useMediaQuery } from 'react-responsive';
import { Link, useNavigate } from 'react-router-dom';
import { getAllParentModules } from '../../apis/backend/modules';
import logo from '../../assets/logos/favicone.svg';
import Popovers from '../../components/bootstrap/Popovers';
import Icon from '../../components/icon/Icon';
import AuthContext from '../../contexts/authContext';
import { EmployeeTable } from '../../gestioneerMenu';
import useAsideTouch from '../../hooks/useAsideTouch';
import { demoPages } from '../../menu';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);
	const [modulesMenu, setModulesMenu] = useState([]);
	// console.log('modul menu', modulesMenu)
	const navigate = useNavigate();

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';
	const isMobile = useMediaQuery({ query: '(max-width: 991.98px)' });
	const isDesktop = !isMobile;


	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);


	const { userData, setUserData } = useContext(AuthContext);

	const navigationLinks = [
		{ id: 8, path: '/statistique/vente/', icon: 'Dashboard', title: "Statistiques" },
		{ id: 1, path: '/pointVente/', icon: 'PointOfSale', title: "Point de ventes" },
		{ id: 2, path: '/stock/', icon: 'MapsHomeWork', title: "Stock" },
		{ id: 3, path: '/clients/', icon: 'Contacts', title: "Client" },
		{ id: 4, path: '/commandes/', icon: 'ShoppingBag', title: "Commandes" },
		{ id: 6, path: '/impaye/', icon: 'PendingActions', title: "Crédits" },
		{ id: 7, path: '/achats/', icon: 'LocalGroceryStore', title: "Achats" },
		// { id: 9, path: null, icon: 'AddTask' },
		{ id: 5, path: '/recette-caisse', icon: 'ReceiptLong', title: "Recettes" },

	];

	useEffect(() => {
		if (userData?.accessToken && userData?.role == 'employe') {
			getAllParentModules(userData?.accessToken, (modules) => {
				setModulesMenu(modules);
			});
		}
	}, []);
	const currentUrl = window.location.href;

	return (
		<>
			<motion.aside style={asideStyle} className={classNames('aside', 'close')}>
				<div className='aside-head'>
					<div className='brand justify-content-center'>
						<Link to='/' aria-label='Logo'>
							<img src={logo} alt='logo' height={36} />
						</Link>
					</div>
				</div>
				<hr className={classNames('navigation-line', "mx-3 border-dark")} />

				<div className='aside-body'>
					{!!modulesMenu?.length &&
						modulesMenu.map((module, moduleIndex) => {
							if (module?.subModules?.length) {
								return (
									<div className='navigation' key={moduleIndex}>
										{module.subModules.map((item, itemIndex) => {
											const isActive = currentUrl.substring(1).includes(item?.route?.path);
											//console.log("item", item);

											return (
												<Popovers
													key={itemIndex}
													desc={<div className='text-info'>{item?.name}</div>}
													trigger='hover'
													placement='right-end'
												>
													<Link to={item?.route?.path}>
														<div className='navigation-item cursor-pointer'>
															<span
																className={`navigation-link navigation-link-info navigation-link-pill ${isActive && "bg-l10-info text-info"
																	}`}
															>
																<Icon icon={item?.icon} className='navigation-icon' />
															</span>
														</div>
													</Link>
												</Popovers>
											);
										})}
									</div>
								);
							}
							return null; // Ensure a value is returned for all `modulesMenu.map` items
						})}


					{/* {navigationLinks?.map((item) => {
						const isActive = currentUrl.substring(1).includes(item?.path);
						return (
							<div className='navigation'>
								<Popovers desc={<div className='text-info'>{item?.title}</div>} trigger='hover' placement='right-end'>
									<Link to={item?.path}>
										<div
											className={`navigation-item cursor-pointer `}
										>
											<span className={`navigation-link navigation-link-info navigation-link-pill ${isActive && "bg-l10-info text-info"}`}>
												<Icon icon={item?.icon} className='navigation-icon' />
											</span>
										</div>
									</Link>
								</Popovers>
							</div>

						)

					})} */}
				</div>
				<div className='aside-foot'>
					<nav aria-label='aside-bottom-user-menu'>
						<div className='navigation'>
							<Popovers desc={<div className='text-info'>{userData?.username}</div>} trigger='hover' placement='right-end'>
								{/* <Link to={`../${EmployeeTable.employee.path}/${userData?.employee}`}> */}

								<div
									role='presentation'
									className='navigation-item cursor-pointer'
								>
									<span className={`navigation-link navigation-link-info navigation-link-pill ${currentUrl.substring(1).includes(`/${EmployeeTable.employee.path}/${userData?.employee}`) && "bg-l10-info text-info"}`}>
										<Icon icon='Person' className='navigation-icon' />
									</span>
								</div>
								{/* </Link> */}
							</Popovers>
						</div>
					</nav>
					<hr className={classNames('navigation-line', "mx-3 border-dark")} />
					<nav aria-label='aside-bottom-user-menu'>
						<div className='navigation'>
							<Popovers desc={<div className='text-info'>Clôturer Session {userData?.sessionId}</div>} trigger='hover' placement='right-end'>
								<Link to={`../${demoPages.login.path}`}>
									<div
										role='presentation'
										className='navigation-item cursor-pointer'
										onClick={() => {
											if (setUserData) {
												setUserData({});
											}
											localStorage.removeItem('calls');
											localStorage.removeItem('paniers');
										}}>
										<span className='navigation-link navigation-link-pill'>
											<span className='navigation-link-info'>
												<Icon icon='Logout' className='navigation-icon' />
											</span>
										</span>
									</div>
								</Link>
							</Popovers>
						</div>
					</nav>

				</div>
			</motion.aside >
		</>
	);
};

export default Aside;
